import React, { useState, useEffect } from "react";
import tyres from "../img/searchImage.jpeg";
import approved from "../img/checkmark.svg";
import { Checkbox } from "../Checkbox";
import "./SearchBar.css";
import { Select } from "../Select";
import { useHistory } from "react-router-dom";
import { Brand } from "../api/BrandApi";
import { SearchOptions, getSearchOptions } from "../api/TyreApi";
import { useTranslation } from "react-i18next";
import { parseIntOrReturnValue, sortValue } from "../Utils";
import { useEffectOnce } from "react-use";

export enum Selection {
  HEIGHT = "height",
  WIDTH = "width",
  RADIUS = "radius",
  SEASON = "season",
  TYPE = "type",
  LOADINDEX = "loadindex",
  SPEEDINDEX = "speedindex",
  WET_ROAD_EFFICIENCY = "wet_road_efficiency",
  FUEL_EFFICIENCY = "fuel_efficiency",
}

export function SearchBar({
  brands,
  options,
}: {
  brands: Brand[];
  options: SearchOptions;
}) {
  const { t } = useTranslation("general");

  const approvedServices = [
    t("approvedServices.approvedService1"),
    t("approvedServices.approvedService2"),
    t("approvedServices.approvedService3"),
    t("approvedServices.approvedService4"),
  ];

  const [runflat, setRunFlat] = useState(false);
  const [versterkt, setVersterkt] = useState(false);
  const [extra, setExtra] = useState(false);
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [diameter, setDiameter] = useState("");
  const [season, setSeason] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [brand, setBrand] = useState("");
  const [loadIndex, setLoadIndex] = useState("");
  const [speedIndex, setSpeedIndex] = useState("");
  const [localSearchOptions, setLocalSearchOptions] = useState(options);
  const [firstSelected, setFirstSelected] = useState<null | Selection>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const availableBrands = brands.filter(b =>
    localSearchOptions.brand.includes(b.name)
  );

  useEffectOnce(() => {
    setLocalSearchOptions(options);
  });

  useEffect(() => {
    setLoading(true);
    const params = new URLSearchParams();
    params.set("height", height);
    params.set("width", width);
    params.set("diameter", diameter);
    params.set("season", season);
    params.set("vehicleType", vehicleType);
    params.set("brand", brand);
    params.set("loadIndex", loadIndex);
    params.set("speedIndex", speedIndex);
    params.set("runflat", runflat ? "true" : "");

    getSearchOptions(params.toString()).then(result => {
      setLocalSearchOptions(result);
      setLoading(false);
    });
  }, [
    height,
    width,
    diameter,
    season,
    vehicleType,
    brand,
    loadIndex,
    speedIndex,
    runflat,
  ]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();

        const params = new URLSearchParams();
        params.set("height", height);
        params.set("width", width);
        params.set("diameter", diameter);
        params.set("season", season);
        params.set("vehicleType", vehicleType);
        params.set("brand", brand);
        params.set("loadIndex", loadIndex);
        params.set("speedIndex", speedIndex);
        params.set("runflat", runflat ? "true" : "");
        params.set("enhanced", versterkt ? "true" : "");

        const keysToDelete: string[] = [];
        params.forEach((value, key) => {
          if (value === "") {
            keysToDelete.push(key);
          }
        });

        keysToDelete.forEach(key => {
          params.delete(key);
        });

        history.push(`/search?${params.toString()}`);
      }}
    >
      <div className="searchBar">
        <img src={tyres} alt="Search image" />
      </div>
      <div className="searchRow flex">
        <div className="search-pros">
          <div className="approvedTable">
            {approvedServices.map(approvedService => (
              <div className="approvedItem flex">
                <img src={approved} alt={approvedService} />
                <h4>{approvedService}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className="search-bar">
          <div className="searchOptions">
            <div className="title">{t("searchBar")}</div>
            <div className="middleOptions">
              <div>
                <Select
                  search={true}
                  title={t("tyre.width").toString()}
                  tooltip={t("tyre.widthTooltip").toString()}
                  options={
                    loading
                      ? [{ label: "Aan het laden...", value: "" }]
                      : [
                          { value: "", label: "" },
                          { value: "reset", label: "Reset" },
                        ].concat(
                          Array.from(
                            new Set(
                              (firstSelected === Selection.WIDTH
                                ? options.width
                                : localSearchOptions.width
                              )
                                .map(i => parseIntOrReturnValue(i))
                                .sort(sortValue)
                                .map(i => i.toString())
                            )
                          ).map(certainWidth => ({
                            value: certainWidth.toString(),
                            label: certainWidth.toString(),
                          }))
                        )
                  }
                  selected={width}
                  selectOption={selected => {
                    setWidth(selected === "reset" ? "" : selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.WIDTH);
                    }

                    if (
                      selected === "reset" &&
                      firstSelected === Selection.WIDTH
                    ) {
                      setFirstSelected(null);
                    }
                  }}
                />
                {extra ? (
                  <>
                    <Select
                      search={true}
                      title={t("tyre.loadIndex").toString()}
                      tooltip={t("tyre.loadIndexTooltip").toString()}
                      options={
                        loading
                          ? [{ label: "Aan het laden...", value: "" }]
                          : [
                              { value: "", label: "" },
                              { value: "reset", label: "Reset" },
                            ].concat(
                              Array.from(
                                new Set(
                                  (firstSelected === Selection.LOADINDEX
                                    ? options.loadIndex
                                    : localSearchOptions.loadIndex
                                  )
                                    .map(i => parseIntOrReturnValue(i))
                                    .sort(sortValue)
                                    .map(i => i.toString())
                                )
                              ).map(certainWidth => ({
                                value: certainWidth.toString(),
                                label: certainWidth.toString(),
                              }))
                            )
                      }
                      selected={loadIndex}
                      selectOption={selected => {
                        setLoadIndex(selected === "reset" ? "" : selected);

                        if (!firstSelected) {
                          setFirstSelected(Selection.LOADINDEX);
                        }

                        if (
                          selected === "reset" &&
                          firstSelected === Selection.LOADINDEX
                        ) {
                          setFirstSelected(null);
                        }
                      }}
                    />
                  </>
                ) : null}
              </div>
              <div>
                <Select
                  search={true}
                  title={t("tyre.height").toString()}
                  tooltip={t("tyre.heightTooltip").toString()}
                  options={
                    loading
                      ? [{ label: "Aan het laden...", value: "" }]
                      : [
                          { value: "", label: "" },
                          { value: "reset", label: "Reset" },
                        ].concat(
                          Array.from(
                            new Set(
                              (firstSelected === Selection.HEIGHT
                                ? options.height
                                : localSearchOptions.height
                              )
                                .map(i => parseIntOrReturnValue(i))
                                .sort(sortValue)
                                .map(i => i.toString())
                            )
                          ).map(certainHeight => ({
                            value: certainHeight.toString(),
                            label: certainHeight.toString(),
                          }))
                        )
                  }
                  selected={height}
                  selectOption={selected => {
                    setHeight(selected === "reset" ? "" : selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.HEIGHT);
                    }

                    if (
                      selected === "reset" &&
                      firstSelected === Selection.HEIGHT
                    ) {
                      setFirstSelected(null);
                    }
                  }}
                />
                {extra ? (
                  <>
                    <Select
                      search={true}
                      title={t("tyre.speedIndex").toString()}
                      tooltip={t("tyre.speedIndexTooltip").toString()}
                      options={
                        loading
                          ? [{ label: "Aan het laden...", value: "" }]
                          : [
                              { value: "", label: "" },
                              { value: "reset", label: "Reset" },
                            ].concat(
                              (firstSelected === Selection.SPEEDINDEX
                                ? options.speedIndex
                                : localSearchOptions.speedIndex
                              )
                                .sort()
                                .map(speedRating => ({
                                  value: speedRating.toString(),
                                  label: speedRating.toString(),
                                }))
                            )
                      }
                      selected={speedIndex}
                      selectOption={selected => {
                        setSpeedIndex(selected === "reset" ? "" : selected);

                        if (!firstSelected) {
                          setFirstSelected(Selection.SPEEDINDEX);
                        }

                        if (
                          selected === "reset" &&
                          firstSelected === Selection.SPEEDINDEX
                        ) {
                          setFirstSelected(null);
                        }
                      }}
                    />
                  </>
                ) : null}
              </div>
              <div>
                <Select
                  search={true}
                  title={t("tyre.diameter").toString()}
                  tooltip={t("tyre.diameterTooltip").toString()}
                  options={
                    loading
                      ? [{ label: "Aan het laden...", value: "" }]
                      : [
                          { value: "", label: "" },
                          { value: "reset", label: "Reset" },
                        ].concat(
                          (firstSelected === Selection.RADIUS
                            ? options.diameter
                            : localSearchOptions.diameter
                          )
                            .map(i => parseIntOrReturnValue(i))
                            .sort(sortValue)
                            .map(certainRadius => ({
                              value: certainRadius.toString(),
                              label: certainRadius.toString(),
                            }))
                        )
                  }
                  selected={diameter}
                  selectOption={selected => {
                    setDiameter(selected === "reset" ? "" : selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.RADIUS);
                    }

                    if (
                      selected === "reset" &&
                      firstSelected === Selection.RADIUS
                    ) {
                      setFirstSelected(null);
                    }
                  }}
                />
                {extra ? (
                  <>
                    <Select
                      search={true}
                      title={t("tyre.brand").toString()}
                      tooltip=""
                      options={[{ value: "", label: "" }].concat(
                        availableBrands
                          .sort((a, b) => {
                            return a.name < b.name
                              ? -1
                              : a.name > b.name
                              ? 1
                              : 0;
                          })
                          .map(brand => ({
                            value: brand.id,
                            label: brand.name,
                          }))
                      )}
                      selected={brand}
                      selectOption={selected => {
                        setBrand(selected);
                      }}
                    />
                  </>
                ) : null}
              </div>

              <div>
                <Select
                  search={true}
                  title={t("tyre.season.title").toString()}
                  tooltip=""
                  options={
                    loading
                      ? [{ label: "Aan het laden...", value: "" }]
                      : [
                          { value: "", label: "" },
                          { value: "reset", label: "Reset" },
                        ].concat(
                          (firstSelected === Selection.SEASON
                            ? options.season
                            : localSearchOptions.season
                          )
                            .sort()
                            .map(tyre => ({
                              value: tyre.toString(),
                              label: t(tyre.toString()),
                            }))
                        )
                  }
                  selected={season}
                  selectOption={selected => {
                    setSeason(selected === "reset" ? "" : selected);

                    if (!firstSelected) {
                      setFirstSelected(Selection.SEASON);
                    }

                    if (
                      selected === "reset" &&
                      firstSelected === Selection.SEASON
                    ) {
                      setFirstSelected(null);
                    }
                  }}
                />
                {extra ? (
                  <Select
                    search={true}
                    title={t("tyre.vehicle").toString()}
                    tooltip=""
                    options={
                      loading
                        ? [{ label: "Aan het laden...", value: "" }]
                        : [
                            { value: "", label: "" },
                            { value: "reset", label: "Reset" },
                          ].concat(
                            (firstSelected === Selection.TYPE
                              ? options.vehicleType
                              : localSearchOptions.vehicleType
                            )
                              .sort()
                              .map(type => ({
                                value: type.toString(),
                                label: type.toString(),
                              }))
                          )
                    }
                    selected={vehicleType}
                    selectOption={selected => {
                      setVehicleType(selected === "reset" ? "" : selected);

                      if (!firstSelected) {
                        setFirstSelected(Selection.TYPE);
                      }

                      if (
                        selected === "reset" &&
                        firstSelected === Selection.TYPE
                      ) {
                        setFirstSelected(null);
                      }
                    }}
                  />
                ) : null}
              </div>
              <div>
                <div>
                  <button className="search" type={"submit"}>
                    {t("buttons.search")}
                  </button>
                </div>
                <a
                  className="moreOptions"
                  onClick={() => {
                    setExtra(!extra);
                  }}
                >
                  {extra ? t("buttons.lessOptions") : t("buttons.moreOptions")}
                </a>
                {extra ? (
                  <>
                    <div style={{ marginTop: "20px" }}>
                      <Checkbox
                        title={t("tyre.runflat").toString()}
                        value={runflat}
                        onClick={checked => {
                          setRunFlat(checked);
                        }}
                      />
                      <Checkbox
                        title={t("tyre.enhanced").toString()}
                        value={versterkt}
                        onClick={checked => {
                          setVersterkt(checked);
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
