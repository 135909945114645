import React from "react";
import Speedindex from "./img/Speedindex.png";
import Loadindex from "./img/Loadindex.png";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { isNL } from "./Utils";

export function TyreGuide() {
  const { t } = useTranslation("tyre-guide");

  return (
    <>
      <Helmet>
        <title>{!isNL() ? t("documentTitle") : "Bandengids van Banden-Koning.nl!"}</title>
        <meta name="description" content={t("meta")} />
      </Helmet>
      <div className="containerTransparant">
        <h1 className="green">{t("title")}</h1>
        <div dangerouslySetInnerHTML={{ __html: t("page") }}></div>
        <div className="flex justify-evenly">
          <img src={Speedindex} className="w-30" />
          <img src={Loadindex} className="w-45" />
        </div>
      </div>
    </>
  );
}
